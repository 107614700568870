/**
 * The Fonts Faces
 */
@font-face {
    font-family: "Google";
    src: url("../Fonts/Google/GoogleSans-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: "icomoon";
    src:
        url("../Fonts/Icomoon/icomoon.ttf?9i6t86") format("truetype"),
        url("../Fonts/Icomoon/icomoon.woff?9i6t86") format("woff"),
        url("../Fonts/Icomoon/icomoon.svg?9i6t86#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}



/**
 * The Main Styles
 */
html, body, #root {
    min-height: var(--full-height);
    overscroll-behavior-y: none;
}
body {
    margin: 0;
    padding: 0;
    font-size: var(--font-size);
    color: var(--font-color);
    background-color: var(--background-color);
    overflow: hidden;
}

.simple-link {
    color: var(--primary-color);
    text-decoration: none;
    cursor: pointer;
}
.simple-link:hover {
    text-decoration: underline;
}

.right-space.right-space {
    margin-right: 8px;
}
.right-space-big.right-space-big {
    margin-right: 16px;
}
.left-space.left-space {
    margin-left: 8px;
}
.top-space.top-space {
    margin-top: 8px;
}
.top-space-big.top-space-big {
    margin-top: 24px;
}
.bottom-space.bottom-space {
    margin-bottom: 8px;
}



/**
 * Results
 */
.text-green.text-green.text-green {
    color: var(--success-color);
}
.text-yellow.text-yellow.text-yellow {
    color: var(--warning-color);
}
.text-red.text-red.text-red {
    color: var(--error-color);
}



/**
 * The Scrollbars
 */
:not(textarea):not(tbody):not(.no-scrollbars):not(.tox-toolbar--scrolling)::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}
.border-scrollbars::-webkit-scrollbar:vertical {
    border-left: 1px solid var(--border-color-light);
}
.border-scrollbars::-webkit-scrollbar:horizontal {
    border-top: 1px solid var(--border-color-light);
}

.border-scrollbars::-webkit-scrollbar-thumb,
:hover:not(textarea):not(tbody):not(.no-scrollbars):not(.tox-toolbar--scrolling)::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 999px;
    box-shadow: inset 0 0 0 4px rgba(0, 0, 0, 0.1);
}
.border-scrollbars::-webkit-scrollbar-thumb:hover,
:not(textarea):not(tbody):not(.no-scrollbars):not(.tox-toolbar--scrolling)::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 0 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.border-scrollbars:focus::-webkit-scrollbar-thumb:vertical,
:not(textarea):not(tbody):not(.no-scrollbars):not(.tox-toolbar--scrolling)::-webkit-scrollbar-thumb:vertical {
    border-left-width: 3px;
    min-height: 20px;
}
.border-scrollbars:focus::-webkit-scrollbar-thumb:horizontal,
:not(textarea):not(tbody):not(.no-scrollbars):not(.tox-toolbar--scrolling)::-webkit-scrollbar-thumb:horizontal {
    border-top-width: 3px;
    min-width: 20px;
}



/**
 * The Font
 */
body,
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="time"],
select,
textarea {
    font-family: var(--main-font);
}
