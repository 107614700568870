
.icon-gestion-bos:before {
  content: "\e9b2";
}
.icon-google-sheets:before {
  content: "\e9b0";
}
.icon-forze:before {
  content: "\e9ae";
}
.icon-omnitec:before {
  content: "\e9ad";
}
.icon-nubila:before {
  content: "\e9ab";
}
.icon-openai:before {
  content: "\e9a6";
}
.icon-reddit:before {
  content: "\e98c";
}
.icon-gmail:before {
  content: "\e971";
}
.icon-google:before {
  content: "\e970";
}
.icon-tiktok:before {
  content: "\e925";
}
.icon-twitter:before {
  content: "\e926";
}
.icon-sms:before {
  content: "\e920";
}
.icon-messenger:before {
  content: "\e921";
}
.icon-telegram:before {
  content: "\e922";
}
.icon-instagram:before {
  content: "\e923";
}
.icon-whatsapp:before {
  content: "\e924";
}
.icon-outlook:before {
  content: "\e98d";
}
.icon-skype:before {
  content: "\e984";
}
.icon-mastodon:before {
  content: "\e980";
}
.icon-quora:before {
  content: "\e981";
}
.icon-twitch:before {
  content: "\e982";
}
.icon-discord:before {
  content: "\e983";
}
.icon-tumblr:before {
  content: "\e985";
}
.icon-linkedin:before {
  content: "\e986";
}
.icon-pinterest:before {
  content: "\e987";
}
.icon-snapchat:before {
  content: "\e988";
}
.icon-wechat:before {
  content: "\e989";
}
.icon-facebook:before {
  content: "\e98a";
}
.icon-youtube:before {
  content: "\e98b";
}
.icon-mercado-libre:before {
  content: "\e9ce";
}
.icon-active:before {
  content: "\e9ca";
}
.icon-completed:before {
  content: "\e9cb";
}
.icon-draft:before {
  content: "\e9cc";
}
.icon-paused:before {
  content: "\e9cd";
}
.icon-booking:before {
  content: "\e9c6";
}
.icon-cancelled:before {
  content: "\e9c7";
}
.icon-checkedin:before {
  content: "\e9c8";
}
.icon-checkedout:before {
  content: "\e9c9";
}
.icon-report:before {
  content: "\e9b7";
}
.icon-ticket-closed:before {
  content: "\e97d";
}
.icon-ticket-open:before {
  content: "\e9a1";
}
.icon-bubble:before {
  content: "\e93e";
}
.icon-restart:before {
  content: "\e969";
}
.icon-variable:before {
  content: "\e91f";
}
.icon-action-log:before {
  content: "\e902";
}
.icon-condition:before {
  content: "\e91c";
}
.icon-field:before {
  content: "\e932";
}
.icon-login:before {
  content: "\e935";
}
.icon-details:before {
  content: "\e96a";
}
.icon-access:before {
  content: "\e901";
}
.icon-action:before {
  content: "\e904";
}
.icon-address:before {
  content: "\e907";
}
.icon-admin:before {
  content: "\e908";
}
.icon-assign:before {
  content: "\e90d";
}
.icon-attachment:before {
  content: "\e90f";
}
.icon-banner:before {
  content: "\e919";
}
.icon-campaign:before {
  content: "\e91e";
}
.icon-category:before {
  content: "\e930";
}
.icon-channel:before {
  content: "\e931";
}
.icon-clear:before {
  content: "\e937";
}
.icon-contact-date:before {
  content: "\e93c";
}
.icon-copy:before {
  content: "\e940";
}
.icon-directory:before {
  content: "\e943";
}
.icon-document-url:before {
  content: "\e944";
}
.icon-error-log:before {
  content: "\e94d";
}
.icon-error:before {
  content: "\e94e";
}
.icon-export:before {
  content: "\e95a";
}
.icon-flow:before {
  content: "\e95e";
}
.icon-forward:before {
  content: "\e95f";
}
.icon-image:before {
  content: "\e961";
}
.icon-import:before {
  content: "\e962";
}
.icon-integration:before {
  content: "\e963";
}
.icon-jump:before {
  content: "\e966";
}
.icon-media:before {
  content: "\e967";
}
.icon-message:before {
  content: "\e96c";
}
.icon-monitoring:before {
  content: "\e96e";
}
.icon-mute:before {
  content: "\e96f";
}
.icon-name:before {
  content: "\e973";
}
.icon-nights:before {
  content: "\e974";
}
.icon-note:before {
  content: "\e975";
}
.icon-notification:before {
  content: "\e977";
}
.icon-order:before {
  content: "\e97b";
}
.icon-plan:before {
  content: "\e98f";
}
.icon-print:before {
  content: "\e993";
}
.icon-product:before {
  content: "\e998";
}
.icon-profile:before {
  content: "\e99b";
}
.icon-provider-log:before {
  content: "\e99c";
}
.icon-province:before {
  content: "\e99f";
}
.icon-query-log:before {
  content: "\e9a0";
}
.icon-questionnaire:before {
  content: "\e9a2";
}
.icon-reply:before {
  content: "\e9af";
}
.icon-save:before {
  content: "\e9b6";
}
.icon-summary:before {
  content: "\e9b8";
}
.icon-tag:before {
  content: "\e9bb";
}
.icon-ticket-type:before {
  content: "\e9bc";
}
.icon-unmute:before {
  content: "\e9bd";
}
.icon-video:before {
  content: "\e9bf";
}
.icon-wait:before {
  content: "\e9c4";
}
.icon-waitlist:before {
  content: "\e9c5";
}
.icon-account:before {
  content: "\e914";
}
.icon-addon:before {
  content: "\e91b";
}
.icon-contact:before {
  content: "\e933";
}
.icon-help:before {
  content: "\e939";
}
.icon-hide:before {
  content: "\e94b";
}
.icon-hospitality-date:before {
  content: "\e951";
}
.icon-hospitality-move:before {
  content: "\e955";
}
.icon-hospitality-smdr:before {
  content: "\e957";
}
.icon-hospitality:before {
  content: "\e960";
}
.icon-info:before {
  content: "\e96d";
}
.icon-observations:before {
  content: "\e97e";
}
.icon-password:before {
  content: "\e98e";
}
.icon-phone:before {
  content: "\e996";
}
.icon-room:before {
  content: "\e999";
}
.icon-status:before {
  content: "\e9ac";
}
.icon-store:before {
  content: "\e9b3";
}
.icon-unread:before {
  content: "\e9b4";
}
.icon-view:before {
  content: "\e9b5";
}
.icon-widget:before {
  content: "\e9be";
}
.icon-followup:before {
  content: "\e92d";
}
.icon-queue:before {
  content: "\e93a";
}
.icon-schedule:before {
  content: "\e94f";
}
.icon-time:before {
  content: "\e96b";
}
.icon-conversation:before {
  content: "\e905";
}
.icon-delete:before {
  content: "\e906";
}
.icon-edit:before {
  content: "\e90c";
}
.icon-email-queue:before {
  content: "\e927";
}
.icon-email:before {
  content: "\e92a";
}
.icon-progress:before {
  content: "\e92b";
}
.icon-resolved:before {
  content: "\e938";
}
.icon-send:before {
  content: "\e94c";
}
.icon-white-email:before {
  content: "\e952";
}
.icon-document:before {
  content: "\e900";
}
.icon-settings:before {
  content: "\e991";
}
.icon-client:before {
  content: "\e913";
}
.icon-invite:before {
  content: "\e916";
}
.icon-partner:before {
  content: "\e917";
}
.icon-team:before {
  content: "\e91a";
}
.icon-user:before {
  content: "\e956";
}
.icon-connector-log:before {
  content: "\e942";
}
.icon-connector:before {
  content: "\e949";
}
.icon-dashboard:before {
  content: "\e9c3";
}
.icon-interpret:before {
  content: "\e9a8";
}
.icon-file:before {
  content: "\f016";
}
.icon-file-text:before {
  content: "\f0f6";
}
.icon-file-pdf:before {
  content: "\f1c1";
}
.icon-file-document:before {
  content: "\f1c2";
}
.icon-file-spreadsheet:before {
  content: "\f1c3";
}
.icon-file-presentation:before {
  content: "\f1c4";
}
.icon-file-zip:before {
  content: "\f1c6";
}
.icon-file-audio:before {
  content: "\f1c7";
}
.icon-file-video:before {
  content: "\f1c8";
}
.icon-file-code:before {
  content: "\f1c9";
}
.icon-assistant-log:before {
  content: "\e9ba";
}
.icon-assistant:before {
  content: "\e9b9";
}
.icon-documents:before {
  content: "\e9a4";
}
.icon-inbox:before {
  content: "\e97c";
}
.icon-publish:before {
  content: "\e9aa";
}
.icon-qualification-bad:before {
  content: "\e9c0";
}
.icon-qualification-excellent:before {
  content: "\e9c1";
}
.icon-qualification-good:before {
  content: "\e9c2";
}
.icon-ad:before {
  content: "\e9a5";
}
.icon-list:before {
  content: "\e9b1";
}
.icon-logs:before {
  content: "\e9a9";
}
.icon-assistant-model:before {
  content: "\e9a7";
}
.icon-simulate:before {
  content: "\e9a3";
}
.icon-fetch:before {
  content: "\e99d";
}
.icon-webhook:before {
  content: "\e99e";
}
.icon-link:before {
  content: "\e964";
}
.icon-back:before {
  content: "\e93b";
}
.icon-format:before {
  content: "\e99a";
}
.icon-move:before {
  content: "\e997";
}
.icon-drag:before {
  content: "\e995";
}
.icon-personalize:before {
  content: "\e994";
}
.icon-pause:before {
  content: "\e992";
}
.icon-phone-number:before {
  content: "\e990";
}
.icon-qualification:before {
  content: "\e95b";
}
.icon-logout:before {
  content: "\e97f";
}
.icon-circle:before {
  content: "\e97a";
}
.icon-star-outline:before {
  content: "\e978";
}
.icon-star:before {
  content: "\e979";
}
.icon-subscription:before {
  content: "\e976";
}
.icon-title:before {
  content: "\e972";
}
.icon-double-check:before {
  content: "\e965";
}
.icon-hotel:before {
  content: "\e95d";
}
.icon-merge:before {
  content: "\e95c";
}
.icon-code:before {
  content: "\e950";
}
.icon-strikethrough:before {
  content: "\e954";
}
.icon-italic:before {
  content: "\e958";
}
.icon-bold:before {
  content: "\e959";
}
.icon-minus:before {
  content: "\e941";
}
.icon-request:before {
  content: "\e93f";
}
.icon-options:before {
  content: "\e93d";
}
.icon-start:before {
  content: "\e934";
}
.icon-emoji:before {
  content: "\e92f";
}
.icon-none:before {
  content: "\e92e";
}
.icon-block:before {
  content: "\e92e";
}
.icon-return:before {
  content: "\e929";
}
.icon-origin:before {
  content: "\e92c";
}
.icon-template:before {
  content: "\e91d";
}
.icon-ticket:before {
  content: "\e918";
}
.icon-money:before {
  content: "\e90e";
}
.icon-currency:before {
  content: "\e90e";
}
.icon-id:before {
  content: "\e909";
}
.icon-amount:before {
  content: "\e909";
}
.icon-menu:before {
  content: "\e903";
}
.icon-country:before {
  content: "\e936";
}
.icon-check:before {
  content: "\e968";
}
.icon-checkedbox:before {
  content: "\efff";
}
.icon-checkbox:before {
  content: "\effe";
}
.icon-language:before {
  content: "\e953";
}
.icon-more:before {
  content: "\e94a";
}
.icon-last:before {
  content: "\e946";
}
.icon-first:before {
  content: "\e947";
}
.icon-prev:before {
  content: "\e948";
}
.icon-filter:before {
  content: "\e945";
}
.icon-add:before {
  content: "\e928";
}
.icon-create:before {
  content: "\e928";
}
.icon-plus:before {
  content: "\e928";
}
.icon-search:before {
  content: "\e915";
}
.icon-close:before {
  content: "\e912";
}
.icon-open:before {
  content: "\e910";
}
.icon-expand:before {
  content: "\e910";
}
.icon-closed:before {
  content: "\e911";
}
.icon-next:before {
  content: "\e911";
}
.icon-up:before {
  content: "\e90a";
}
.icon-down:before {
  content: "\e90b";
}
